import JSZip from "jszip";

export default class FileService {

    static  formatBytes (bytes) {
        if (bytes === 0) return '0 B';

        const k = 1024;
        const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    }

    static fileToBlob(file) {
        if (typeof file === Blob) return file;
        return new Blob([file], {type: file.type});
    }

    static async getBlobByUrl(url) {
        if (FileService.isBlob(url)) {
            return url;
        }
        try {
            const response = await fetch(url);
            if (!response.ok) { // 检查响应状态
                throw new Error('Network response was not ok: ' + response.statusText);
            }
            return await response.blob();
        } catch (error) {
            console.error('Error fetching the blob:', error);
            throw error; // 抛出错误让调用者处理
        }
    }

    static isBlob(object) {
        return object instanceof Blob;
    }

    /**
     * @param file_url string|Blob
     * @param file_name string
     * @returns {Promise<void>}
     */
    static async download(file_url,file_name) {
        let blob = null;
        if (FileService.isBlob(file_url)) {
            blob = file_url;
        } else {
            blob = await FileService.getBlobByUrl(file_url)
        }

        const blobUrl = URL.createObjectURL(blob);

        const downloadLink = document.createElement('a');
        downloadLink.href = blobUrl;
        downloadLink.download = file_name;

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(blobUrl);
    }

    static getBaseName(file_name){
        // 获取文件名，不带扩展名
        const basename = file_name.lastIndexOf('.') === -1
            ? file_name // 如果没有扩展名，返回原始文件名
            : file_name.substring(0, file_name.lastIndexOf('.'));
        return basename;
    }

     static getExt(file_name){
        return file_name.split('.').pop().toLowerCase();
    }

    static async downloadAsZip(files,name = (new Date()).getTime()+'.zip',file_url = 'file_url',save_name = 'save_name') {
        const jszip = new JSZip();

        for (const file of files) {
            const blob = await FileService.getBlobByUrl(file[file_url]);
            jszip.file(file[save_name], blob);
        }

        try {
            // 使用await等待异步操作完成
            const content = await jszip.generateAsync({type: "blob"});
            await FileService.download(content, name);
        } catch (error) {
            console.error('Error while generating zip:', error);
        }

    }



}