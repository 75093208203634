<script>
import ImgService from "@/service/ImgService";
import FileService from "@/service/FileService";
export default {
  data() {
    return {
      resFileList : [],
      fileList: [],
      compressFileList : [],
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      compressVal: 100,
      compressValue : 0,
      imgExtList : [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/webp",
        "image/gif",
      ],
      imgExt : "",
      imgExtVal : "",
    };
  },
  methods: {
    async startDownload() {
      this.resFileList.forEach((item) => {
        FileService.download(item.file,item.save_name);
      });
    },
    async startDownloadAsZip(){
      const loading = this.$loading({
        lock: true,
        text: '打包下载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      try {
        await FileService.downloadAsZip(this.resFileList, (new Date()).getTime() + this.imgExtVal.split('/')[1]+'图包.zip', 'file', 'save_name');
      } catch (e) {
        console.log('startDownloadAsZip err' + e.message);
      }
      loading.close();
    },
    async startChangeExt() {
      if (this.imgExt === '') {
        return this.$message.alert('请选格式');
      }
      this.resFileList = [];
      let k = 0;
      this.imgExtVal = this.imgExt;
      const loading = this.$loading({
        lock: true,
        text: '正在转格式为'+this.imgExtVal+',比较久...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let after_ext = this.imgExtVal.split('/')[1];
      for (const file of this.fileList) {
        try {
          const resFile = await ImgService.changeExtension(file.raw,this.imgExt)
          const imgBlob = ImgService.fileToBlob(resFile);

          this.resFileList.push({
            before_size: ImgService.formatBytes(file.size),
            file: imgBlob,
            save_name : FileService.getBaseName(file.name) +'.'+ after_ext,
            before_ext : FileService.getExt(file.name),
            after_ext : after_ext,
            file_url : URL.createObjectURL(imgBlob),
            after_size: ImgService.formatBytes(resFile.size),
            k : k++,
          });
        } catch (error) {
          console.error(' Error:', error.message);
        }
      }
      loading.close();
    },
    handleRemove(file) {
      console.log(file);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      console.log(file);
    },
    handleChange(file, files) {
      console.log(file)
      this.fileList = files
    },
  }
}
</script>

<template>
  <div>
    <el-row :gutter="24">
      <el-col :span="12" :offset="6">
        <h1>选图</h1>
        <el-upload
            :auto-upload="false"
            :multiple="true"
            action="#"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :on-change="handleChange"
        >
          <i slot="default" class="el-icon-plus"></i>

        </el-upload>
        <el-dialog :visible.sync="dialogVisible">
          <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
      </el-col>
    </el-row>
    <el-row :gutter="24">
      <el-col :span="12" :offset="6">
        <div style="margin: 0 auto;" class="block">
          <span class="demonstration">选择格式</span>

          <el-select v-model="imgExt" placeholder="图片格式">
            <el-option
                v-for="(item,k) in imgExtList"
                :key="k"
                :label="item"
                :value="item"
            >
            </el-option>
          </el-select>

          <el-button type="primary" plain @click="startChangeExt">开始转格式</el-button>
          <el-button type="success" style="display: none;" plain @click="startDownload" v-show="resFileList.length > 0">下载</el-button>
          <el-button type="warning" plain @click="startDownloadAsZip" v-show="resFileList.length > 0">打包下载</el-button>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="24" v-show="resFileList.length > 0">
      <el-col :span="12" :offset="6">
        <h1>转为{{imgExtVal}}的结果,点击查看大图</h1>
        <el-row :gutter="12" class="row-bg">
          <el-col :span="4"  v-for="(item) in resFileList" :key="item.k">
            <el-image
                style="width: 100px; height: 100px"
                :src="item.file_url"
                :alt="item.save_name"
                :preview-src-list="resFileList.map(it=>it.file_url)"
                fit="scale-down"></el-image>
            <p style="overflow: hidden;">{{item.save_name}}</p>
            <p>{{item.before_ext}}:{{item.before_size}}</p>
            <p>{{item.after_ext}}:{{item.after_size}}</p>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<style scoped>
.el-col{
  border: 1px solid red;
}
</style>