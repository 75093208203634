import { render, staticRenderFns } from "./CompressView.vue?vue&type=template&id=07f2ab79&scoped=true"
import script from "./CompressView.vue?vue&type=script&lang=js"
export * from "./CompressView.vue?vue&type=script&lang=js"
import style0 from "./CompressView.vue?vue&type=style&index=0&id=07f2ab79&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07f2ab79",
  null
  
)

export default component.exports