import Vue from 'vue';
import Router from 'vue-router';
import Home from '../views/HomeView.vue';
import About from '../views/AboutView.vue';
import ImgCompress from '../views/img/CompressView.vue';
import ImgMatting from '../views/img/MattingView.vue';
import ImgExtension from '../views/img/ExtensionView.vue';
import MathProfit   from "../views/math/profitView.vue";
Vue.use(Router);

export default new Router({
    mode: 'history',  // 使用 history 模式
    routes: [
        {
            path: '/',
            name: '首页',
            component: Home
        },
        {
            path: '/about',
            name: '关于',
            component: About
        },
        {
            path: '/img/extension',
            name: '图片转格式',
            component: ImgExtension
        },
        {
            path: '/img/compress',
            name: '图片压缩',
            component: ImgCompress
        },
        {
            path: '/img/matting',
            name: '背景去除|抠图',
            component: ImgMatting
        },
        {
            path: '/math/profit',
            name: '利润计算',
            component: MathProfit
        },
    ]
});
