import Compressor from 'compressorjs';
import FileService from "@/service/FileService";
import imageCompression from 'browser-image-compression';
const { Image } = require('image-js');

export default class ImgService extends FileService {

    static compressImage(file, quality = 0.6) {
        return new Promise((resolve, reject) => {
            new Compressor(file, {
                quality: quality,
                success: resolve,
                error: reject,
            });
        });
    }

    static async changeExt(file_url,format, quality = 100) {
        // 读取原始 PNG 图片
        //const image = await Image.load(file_url);

        Image.load(file_url).then(image => {
            // 将 PNG 图片转换为 JPEG 图片
            image.save('output.jpg', {quality: quality, format: format});

            console.log('图片已成功转换为 JPEG 格式');
        }).catch(err => {
            console.error('图片转换出错：', err);
        });
    }

    static async changeExtension(file,fileType = 'image/jpeg') {
        const options = {
            // maxSizeMB: 1, // 最大文件大小 1MB
            // maxWidthOrHeight: 1920, // 最大宽高 1920px
            useWebWorker: true, // 使用 Web Worker 加速
            fileType: fileType, // 转换为 JPEG 格式
        };

        // 压缩图片
        const compressedFile = await imageCompression(file, options);
        return compressedFile;
        // 转换为 URL
        //this.compressedUrl = URL.createObjectURL(compressedFile);



    }

}