<script>
const Decimal = require('decimal.js');
export default {
  data() {
    return {
      form : {
        total_price : {
          val : '',
          label : '订单总金额(元)',
        },
        fee : {
          val : '',
          label : '手续费比例(%)',
        },
        transaction_fee : {
          val : '',
          label : '提现手续费(元)',
        },
        profit : {
          val : '',
          label : '利润点(%)',
        },
        start_profit : {
          val : '',
          disabled : true,
          label : '开始盈利点(元)',
        },
        total_fee : {
          val : '',
          disabled : true,
          label : '总的手续费(元)',
        },
        transaction_money : {
          val : '',
          disabled : true,
          label : '最终提现金额(元)',
        },
        total_profit : {
          val : '',
          disabled : true,
          label : '总成本(元)',
        },
        final_profit : {
          val : '',
          disabled : true,
          label : '最终利润(元)',
        },
        final_profit_ratio : {
          val : '',
          disabled : true,
          label : '最终利润比例(%)',
        },
      }
    };
  },
  created() {
    this.getLocalStorage();
  },
  methods:  {
    saveLocalStorage() {
      let inputVals = {};
      for (let name in this.form) {
        inputVals[name] = this.form[name].val;
      }
      localStorage.setItem('last_profit_inputs',JSON.stringify(inputVals));
    },
    getLocalStorage() {
      let inputValsJson = localStorage.getItem('last_profit_inputs');
      if (!inputValsJson) return;
      try {
        let inputVals = JSON.parse(inputValsJson);
        for (let name in inputVals) {
          if (this.form[name] === undefined) continue;
          this.form[name].val = inputVals[name];
        }
      } catch (e) {
        console.log(e)
        localStorage.removeItem('last_profit_inputs');
      }
    },
    calculateStartProfit () {
      this.form.start_profit.val = new Decimal(this.form.transaction_fee.val)
          .div(
              new Decimal(1)
                  .minus(new Decimal(this.form.fee.val).div(100))
                  .minus(new Decimal(100).minus(this.form.profit.val).div(100))
          )
          .toString(); // 将结果转换为字符串，避免精度丢失
    },

    calculateFinalProfit() {
      this.form.total_fee.val = new Decimal(this.form.total_price.val)
          .times(this.form.fee.val)
          .div(100)
          .plus(this.form.transaction_fee.val)
          .toString(); // 保持精度并转换为字符串

      // 最终提现金额
      this.form.transaction_money.val = new Decimal(this.form.total_price.val)
          .minus(this.form.total_fee.val)
          .toString();

      // 总成本
      this.form.total_profit.val = new Decimal(this.form.total_price.val)
          .times(new Decimal(100).minus(this.form.profit.val))
          .div(100)
          .toString();

      // 最终利润
      this.form.final_profit.val = new Decimal(this.form.transaction_money.val)
          .minus(this.form.total_profit.val)
          .toString();

      this.form.final_profit_ratio.val = new Decimal(this.form.final_profit.val)
                                        .div(this.form.total_price.val)
                                        .times(100)
                                        .toString();
    },
    calculate() {
      this.calculateFinalProfit();
      this.calculateStartProfit();
      this.saveLocalStorage();
    },
    toNumber (item) {
      const parsedValue = Number(item.val);
      if (isNaN(parsedValue)) {
        // 如果不是数字，则将 item.val 设置为 NaN 或其他处理逻辑
        item.val = 0; // 或者设置为默认数字，例如 0
      } else {
        // 如果是数字，则更新为转换后的数字
        item.val = parsedValue;
      }
    }
  },
}
</script>

<template>
  <div>
    <el-form label-position="left" ref="form" :model="form" label-width="130px">

      <el-form-item v-for="(item,k) in form" :key="k" :label="item.label">
        <el-input
            :placeholder="item.label+' '+k"
            v-model="item.val"
            :disabled="item.disabled === true"
            @change="toNumber(item)"
            clearable>
        </el-input>
      </el-form-item>

      <el-form-item label-width="0">
        <el-button type="primary" @click="calculate">计算</el-button>
        <el-button type="danger" @click="calculate">清空</el-button>
      </el-form-item>


    </el-form>
  </div>
</template>

<style scoped>

</style>